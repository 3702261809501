import EventName from 'digital-scoring/Component/EventName';
import { useParams } from 'react-router-dom';
import TabsRounds from 'digital-scoring/Component/TabsRounds';
import TabsRoundsMobile from '../Component/TabsRoundsMobile';
import Groups from 'digital-scoring/Component/Groups';
import React, { useEffect, useState } from 'react';
import DetailItemLoader from 'components/DetailItemLoader';
import Table from '../Component/Table';
import {
  useParticipantsList,
  useTabsRoundsList,
} from 'digital-scoring/queries/digitalScoringHooks';
import MobileTable from '../Component/MobileTable';
import MobileCard from '../Component/MobileCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SwissLeague = () => {
  const params = useParams();
  const [selected, setSelected] = useState('');
  const [selected2, setSelected2] = useState('');

  const participantsListQueryData = useParticipantsList({
    sport_event: params.sportEvent,
    tab_id: selected,
    group_id: selected2 || '',
  });
  const tabRoundsListQueryData = useTabsRoundsList({
    sport_event: params.sportEvent,
  });

  //   useTabsRoundsList({
  //     sport_event: params.sportEvent,

  const winColour = (row, _columnKey, originalRow) => {
    if (originalRow?.match_status?.toLowerCase() === 'draw') return 'bg-gray-150';
    if (row?.is_won) {
      return 'bg-green-50';
    } else return '';
  };
  const columns = [
    {
      key: 'current_standing',
      label: 'Current Standing',
      component: 'TextColumn',
      callbackRow: (row, _columnKey) => {
        return row.opponent_1;
      },
      bgCallback: winColour,
    },
    {
      key: 'opponent_1',
      label: 'Opponent 1',
      component: 'swissInfoColumn',
      isWon: 'Swiss',
      checkForDraws: 'opponent_2',
      bgCallback: winColour,
    },
    {
      key: 'round_point',
      label: 'Round Point',
      component: 'TextColumn',
      callbackRow: (row, _columnKey) => {
        return row.opponent_1;
      },
      bgCallback: winColour,
    },
    {
      key: 'current_standing',
      label: 'Current Standing',
      component: 'TextColumn',
      callbackRow: (row, _columnKey) => {
        return row.opponent_2;
      },
      bgCallback: winColour,
    },
    {
      key: 'opponent_2',
      label: 'Opponent 2',
      component: 'swissInfoColumn',
      isWon: 'Swiss',
      checkForDraws: 'opponent_1',
      bgCallback: winColour,
    },
    {
      key: 'round_point',
      label: 'Round Point',
      component: 'TextColumn',

      callbackRow: (row, _columnKey) => {
        return row.opponent_2;
      },
      bgCallback: winColour,
    },
  ];
  const finalStandingColumns = [
    {
      key: '',
      label: 'Name',
      component: 'swissInfoColumn',
    },
    {
      key: 'points',
      label: 'Total Points',
      component: 'TextColumn',
    },
    {
      key: 'match_count',
      label: 'Matches',
      component: 'TextColumn',
    },
    {
      key: 'win',
      label: 'Won',
      component: 'TextColumn',
      callbackRow: (row) => {
        return row.matches;
      },
    },
    {
      key: 'loss',
      label: 'Lost',
      component: 'TextColumn',
      callbackRow: (row) => {
        return row.matches;
      },
    },
    {
      key: 'draw',
      label: 'Draws',
      component: 'TextColumn',
      callbackRow: (row) => {
        return row.matches;
      },
    },
    {
      key: 'rank',
      label: 'Results',
      component: 'ResultButton',
    },
  ];

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected(tabRoundsListQueryData?.data[0]?.tab_id)
      : setSelected('');
  }, []);

  useEffect(() => {
    tabRoundsListQueryData.data
      ? setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id)
      : setSelected2('');
  }, [selected]);

  useEffect(() => {
    participantsListQueryData.refetch();
    // console.log(participantsListQueryData.data);
  }, [selected, selected2, params.sportEvent]);

  const [Group, setGroup] = useState([]);

  const setTabs = (idx) => {
    setSelected(idx);
    let i;
    for (i = 0; i < tabRoundsListQueryData.data.length; i++) {
      if (tabRoundsListQueryData.data[i].tab_id === idx) {
        setSelected2(tabRoundsListQueryData?.data[i]?.groups[0]?.group_id);
        break;
      }
      // setSelected2(tabRoundsListQueryData?.data[0]?.groups[0]?.group_id);
    }
  };

  useEffect(() => {
    if (tabRoundsListQueryData.isFetched) {
      document.querySelector('.tabs')?.click();
    }
  }, [tabRoundsListQueryData.isLoading]);

  const setHeat = (idx) => {
    setSelected2(idx);
  };

  const setGroupOnTabs = (group) => {
    setGroup(group);
  };

  return (
    <div>
      <EventName sport_event={params.sportEvent} />
      {tabRoundsListQueryData.data && tabRoundsListQueryData.data.length !== 0 ? (
        <>
          <TabsRounds
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
          />
          <TabsRoundsMobile
            tabRoundsListQueryData={tabRoundsListQueryData}
            setTabs={setTabs}
            selected={selected}
            setGroupOnTabs={setGroupOnTabs}
          />
        </>
      ) : (
        <div className="w-full h-28 flex flex-col justify-center text-center bg-white font-medium text-lg">
          Rounds are not created yet for this sport event
        </div>
      )}{' '}
      {Group.length > 0 ? (
        <Groups Group={Group} pool={true} setHeat={setHeat} selected2={selected2} />
      ) : null}
      <div className="flex justify-between items-center p-4 hidden -mb-8">
        <p className="font-bold">Match Details</p>
        <FontAwesomeIcon icon={faSearch} className="text-gray-450" />
      </div>
      <DetailItemLoader queryData={participantsListQueryData}>
        {({ data }) =>
          data && data.length > 0 ? (
            <>
              <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg hidden md:block">
                <Table
                  rows={data}
                  columns={
                    selected === 'final_standings' ? finalStandingColumns : columns
                    // selected === 'matches' ? columns2 : selected === 'knockout' ? columns3 : columns
                  }
                />
              </div>

              <div className="flex flex-col gap-4 md:hidden py-4 px-1">
                {data.map((item, index) =>
                  item.opponent_1 ? (
                    <MobileTable key={index} Data={item} isSwissLeague checkForDraws />
                  ) : (
                    <MobileCard key={index} Data={item} />
                  )
                )}
              </div>
            </>
          ) : (
            <div className="my-8 p-5 bg-gradient-to-br from-blue-75 to-purple-75 rounded-lg">
              <div
                className="text-center p-8 text-white font-bold rounded-lg"
                style={{
                  background: `url(/assets/sfa_online/node_modules/knockout-component/src/direflow-components/knockout-component/components/images/pattern.png) no-repeat -5% , conic-gradient(from 51.11deg at 50% 58.75%, #8E2DE2 0deg, #4A00E0 360deg)`,
                }}
              >
                Draws & Schedules will be updated soon. Thank you for your patience.
              </div>
            </div>
          )
        }
      </DetailItemLoader>
    </div>
  );
};

export default SwissLeague;
